export function updateQueryString(params) {
  const originalUrl = new URL(location.href);
  const newParams = new URLSearchParams(originalUrl.searchParams);
  for (const param in params) {
    newParams.set(param, params[param]);
  }

  const urlWithNewParams = new URL(
    `${originalUrl.origin}${originalUrl.pathname}?${newParams}`,
  );
  return urlWithNewParams.toString();
}

export function urlEncodeParams(params?) {
  const paramArray: string[] = [];
  for (const param in params) {
    if (params[param] != null && params.hasOwnProperty(param)) {
      paramArray.push(`${param}=${params[param]}`);
    }
  }
  return `${paramArray.length > 0 ? '?' : ''}${paramArray.join('&')}`;
}

export function getQueryParams() {
  const url = new URL(location.href);
  const params = new URLSearchParams(url.searchParams);
  return Object.fromEntries(params.entries());
}
